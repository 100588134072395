import React from 'react';
import { FaCog } from 'react-icons/fa';

const SpinningGear = ({ size }) => {
  const gearStyle = {
    animation: 'spin infinite 2s linear',
    fontSize: size,
    display: 'flex', // Using flex for centering
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: "30px 0"
  };

  return <div style={gearStyle}><FaCog /></div>;
};

export default SpinningGear;
