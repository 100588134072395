import Logo from "./logo.png";
import "./app.css";
import SpinningGear from "./SpinningGear";

const App = () => {
  return (
    <main className="container">
      <section className="row mt-5">
        <div className="offset-lg-2 col-lg-8">
          <div className="d-flex justify-content-center align-content-center">
            <img src={Logo} className="img-fluid" />
          </div>
          <SpinningGear size={75} />
          <div className="rounded shadow p-3 text-center" style={{ backgroundColor: "#2b2e34" }}>
            <h2>Exciting Developments Coming in 2024!</h2>
            <p>
              <br />
              As we forge ahead into 2024, we're thrilled to share that a host of new and exciting offerings are on the
              horizon here at <strong>SEQTOR</strong>! Our team has been working tirelessly behind the scenes,
              innovating and crafting experiences that we believe will truly delight you.<br />
              <br />
              This year is poised to be a landmark one for us, filled with groundbreaking developments, fresh features,
              and unique offerings that are tailored to enhance your experience with us.<br />
              <br />
              While we're putting the finishing touches on these new ventures, we warmly invite you to stay connected. Be
              sure to check back with us soon – you won't want to miss the incredible things we have in store!<br />
              <br />
              Thank you for your continued support and enthusiasm. The future is bright and full of possibilities, and we
              can't wait to embark on this exciting journey with you.
            </p>
            <p className="contact">
              For inquiries, contact Peter Kristiansen at: <a href="mailto:pk@seqtor.com">pk@seqtor.com</a>
            </p>
          </div>
          <footer className="text-center mt-3">
            <span id="copyright">&copy; {new Date().getFullYear()} </span> SEQTOR ApS
          </footer>
        </div>
      </section>
    </main>
  );
}

export default App;
